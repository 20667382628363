<template>
  <div>
    <div>
      <h4 class="dashboard__heading">Free Resources</h4>
    </div>
    <b-card v-for="(service , index) in services" :key="index" :title="service.frs_name">
      <div>
        <b-tabs>
          <b-tab v-for="(material, index) in service.categories" :key="index" :title="material.frc_name" class="w-100">
            <b-card :class="`${material.frc_name.toLowerCase()}-content`">
              <ul class="d-flex" style="gap: 20px;">
                <li style="list-style-type: none;" v-for="(item, idx) in material.contents" :key="idx">
                  <div v-if="item.frcc_content" class="mb-2">
                    <div  v-if="item.frcc_content.includes('.mp3') || item.frcc_content.includes('.wav')">
                      <audio :src="FILESURL + item.frcc_content" controls></audio>
                    </div>
                    <div v-else>
                      <embed :src="FILESURL + item.frcc_content" width="100%" height="200px" />
                    </div>
                    <a :href="FILESURL + item.frcc_content" target="_blank">Open</a>
                  </div>
                  <span>{{ item.frcc_name }}</span>
                </li>
              </ul>
            </b-card>
          </b-tab>
        </b-tabs>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BTabs, BTab, BCard } from "bootstrap-vue";
import StudentServices from "@/apiServices/StudentServices";
import { FILESURL } from "@/config/index";

export default {
  components: {
    BTabs,
    BTab,
    BCard
  },
  name: "DemoTestTabs",
  data() {
    return {
      FILESURL,
      services: []
    };
  },
  methods: {
    async getServices() {
      const res = await StudentServices.getAllFreeResources();
      this.services = res.data.data;
    }
  },
  beforeMount() {
    this.getServices();
  }
};
</script>

<style scoped>
/* Uncomment the styles if needed
.reading-content,
.listening-content,
.writing-content,
.speaking-content {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin: 15px 0;
}

h3 {
  color: #333;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  padding: 10px 0;
  font-size: 16px;
  color: #555;
}

.b-tabs {
  margin-top: 20px;
}
*/
</style>

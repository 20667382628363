<template>
  <div class="study-abroad__section mt-lg-2">
    <div>
      <h3 class="main-heading">HOW THE DASHBOARD WORKS</h3>
      <h4 class="dashboard__heading">Your Study abroad Journey</h4>
    </div>
    <div class="study-abroad__cards-grid">
      <b-card class="card-app-design profile-status__card study-abroad__card">
        <div class="d-flex">
          <div>
            <h1 class="step-num">1</h1>
          </div>
          <div class="pl-2">
            <b-card-title class="mb-75 step-name">
              Talk with an Aliff Counsellor</b-card-title
            >
          </div>
        </div>
      </b-card>

      <b-card class="card-app-design profile-status__card study-abroad__card">
        <div class="d-flex">
          <div>
            <h1 class="step-num">2</h1>
          </div>
          <div class="pl-2">
            <b-card-title class="mb-75 step-name">
              Explore Universities</b-card-title
            >
          </div>
        </div>
      </b-card>
      <b-card class="card-app-design profile-status__card study-abroad__card">
        <div class="d-flex">
          <div>
            <h1 class="step-num">3</h1>
          </div>
          <div class="pl-2">
            <b-card-title class="mb-75 step-name">
              Complete the profile form
              </b-card-title>
          </div>
        </div>
      </b-card>
      <b-card class="card-app-design profile-status__card study-abroad__card">
        <div class="d-flex">
          <div>
            <h1 class="step-num">4</h1>
          </div>
          <div class="pl-2">
            <b-card-title class="mb-75 step-name">
              Enroll in University courses</b-card-title>
          </div>
        </div>
      </b-card>
      <b-card class="card-app-design profile-status__card study-abroad__card">
        <div class="d-flex">
          <div>
            <h1 class="step-num">5</h1>
          </div>
          <div class="pl-2">
            <b-card-title class="mb-75 step-name">
              Upload Documents</b-card-title
            >
          </div>
        </div>
      </b-card>
      <b-card class="card-app-design profile-status__card study-abroad__card">
        <div class="d-flex">
          <div>
            <h1 class="step-num">6</h1>
          </div>
          <div class="pl-2">
            <b-card-title class="mb-75 step-name">
              Complete your study abroad journey !!</b-card-title
            >
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>
<script>
import {
  BCard,
  BBadge,
  BCardTitle,
  BCardText,
  BAvatar,
  BButton,
  BProgress,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BBadge,
    BButton,
    BAvatar,
    BCardTitle,
    BCardText,
    BProgress,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      // profile_data: this.$props.profile_data
    };
  },
};
</script>
<style>
.study-abroad__section .dashboard__heading {
  color: #000;
}
.study-abroad__section .main-heading {
  color: #5367ff;
}
.study-abroad__cards-grid {
  display: grid;
  grid-template-columns: repeat(6, 16%);
  grid-column-gap: 1%;
}

.profile-status__card .step-num {
  color: rgba(83, 103, 255, 0.3);
  font-size: 45px;
  font-weight: bold;
  margin-top: -6px;
}
.study-abroad__card .step-name {
  font-weight: bold;
  color: #000;
  line-height: 20px;
  font-size: 1rem;
}

.profile-status__card .step-status {
  color: #c8c8c8;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 1rem !important;
}

@media (max-width: 768px) {
  .study-abroad__cards-grid {
    grid-template-columns: repeat(6, 50%);
    grid-column-gap: 2%;
    overflow: auto;
  }
}
</style>